<template>
  <div>
    <b-overlay :show="loading">

      <validation-observer ref="refFormObserver">
        <validation-provider #default="validationContext" name="Name" rules="required">
          <b-form-group :label="$t('message.Name')" for="name">
            <b-form-input  v-model="partners.name"></b-form-input>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </b-form-group>
        </validation-provider>
        <validation-provider #default="validationContext" name="Email" rules="required">
          <b-form-group :label="$t('message.Email')" for="email">
            <b-form-input  v-model="partners.email"></b-form-input>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </b-form-group>
        </validation-provider>

        <validation-provider #default="validationContext" name="Address" rules="required">
          <b-form-group :label="$t('message.Address')" for="address">
            <b-form-input  v-model="partners.address"></b-form-input>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </b-form-group>
        </validation-provider>

        <label class="mt-2">{{$t('message.Regions')}}</label>
        <b-form-select v-model="partners.country_id" :options="countries" text-field="name_uz" value-field="id"
          style="height: 40px" class="w-100" />

        <span class="text-danger" v-if="partners.country_id === null">
          {{ $t('message.errrCountries') }}
        </span>

        <div class="w-100 d-flex justify-content-end mt-3">
          <b-button variant="primary" @click="handleSubmit" :disabled="disabled">
            {{$t('message.Save')}}
          </b-button>
          <b-button variant="warning" class="ml-1" @click="$emit('closeModal')">
            {{$t('message.Cancel')}}
          </b-button>
        </div>
      </validation-observer>
    </b-overlay>

  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
    alphaNum
  } from '@validations'
  import {
    addPartners,
    editPartners,
    getPartnersById
  } from '@/api/partners'
  import {
    getCountriesById
  } from '@/api/countries/countries'

  export default {
    name: 'CountryForm',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      countries: '',
      id: {
        default: null
      }
    },
    data() {
      return {
        required,
        alphaNum,
        partners: {
          id: null,
          name: '',
          email: '',
          address: '',
          country_id: null,
        },
        disabled: false,
        loading: false,
      }
    },
    methods: {
      handleSubmit() {
        const valid = this.$refs.refFormObserver.validate();

        if (valid) {
          this.loading = true;
          this.disabled = true;
          setTimeout(() => {
            this.disabled = false;
            this.loading = false;
          }, 1000)

          let data = {
            id: this.id,
            name: this.partners.name,
            email: this.partners.email,
            address: this.partners.address,
            country_id: this.partners.country_id
          }
          if (this.id) {
            const form = new FormData()
            form.append('id', this.partners.id)
            form.append('name', this.partners.name)
            form.append('email', this.partners.email)
            form.append('address', this.partners.address)
            form.append('address', this.partners.country_id)
            this.partners.id = this.id;

            // eslint-disable-next-line no-unused-vars
            editPartners(data).then(res => {
              this.$emit('closeModal')
            })
          } else {
            // eslint-disable-next-line no-unused-vars
            addPartners(data).then(res => {
              this.$emit('closeModal')
            })
          }
        }
      },
      fetchPartnerById() {
        getPartnersById(this.id).then(res => {
          this.partners = res.data.data;

        })
        getCountriesById().then(res => {
          this.countries = res.data.data
        })
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(val) {
          if (val) {
            this.fetchPartnerById()
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>