<template>
  <div>
    <b-overlay :show="loading">

      <div class="w-100 d-flex justify-content-end">
        <b-button variant="primary" class="mb-1" @click="handleAdd">
          <b-icon icon="plus"></b-icon>{{$t('message.Add')}}
        </b-button>
      </div>
      <b-card>
        <b-table :fields="fields" :items="partners" responsive :busy="busy">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(id)="data">
            <span class="text-md text-primary"> {{ (data.index + 1) }}</span>
          </template>
          <template #cell(action)="data">
            <div class="d-flex">
              <div>
                <b-button variant="outline-warning" size="sm" @click="handleEdit(data.item.id)">
                  <b-icon icon="pencil"></b-icon>
                </b-button>
              </div>
              <div class="ml-1">
                <b-button variant="outline-danger" size="sm" @click="handleDelete(data.item.id)">
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </div>
            </div>
          </template>
        </b-table>
      </b-card>

      <b-pagination v-model="pagination.page" :total-rows="total" :per-page="pagination.pageSize"
        @input="fetchPartners">
      </b-pagination>
    </b-overlay>

    <b-modal hide-footer v-model="formModalActive" no-close-on-backdrop>
      <PartnerForm :id="id" :countries="countries" @closeModal="closeModal" />
    </b-modal>
  </div>
</template>

<script>
  import Swal from 'sweetalert2'
  import ExpenseForm from '@/views/directories/expense-type/components/ExpenseForm'
  import CountryForm from '@/views/directories/country/components/CountryForm'
  import {
    deletePartners,
    getPartners
  } from '@/api/partners'
  import PartnerForm from '@/views/directories/partners/components/PartnerForm'
  import {
    createCountryById
  } from '@/api/countries/countries'

  export default {
    name: 'Index',
    components: {
      PartnerForm,
      CountryForm,
      ExpenseForm
    },
    data() {
      return {
        id: null,
        partners: [],
        countries: [],
        fields: [{
            key: 'id',
            label: 'ID'
          },
          {
            key: 'name',
            label: this.$t('message.name_title')
          },
          {
            key: 'email',
            label: this.$t('message.Email')
          },
          {
            key: 'address',
            label: this.$t('message.Address')
          },
          {
            key: 'country.name_uz',
            label: this.$t('message.Countries')
          },
          {
            key: 'action',
            label: this.$t('message.Action')
          },
        ],
        formModalActive: false,
        pagination: {
          page: 1,
          pageSize: 20
        },
        total: 1,
        busy: true,
        loading: false,
      }
    },
    created() {
      this.fetchPartners()
    },
    methods: {
      fetchPartners() {
        this.loading = true;
        getPartners(this.pagination).then(response => {
          this.partners = response.data.data.data
          this.total = response.data.data.total;
          this.busy = false;
          this.loading = false;
        })
      },
      handleAdd() {
        this.id = null;
        this.formModalActive = true
        createCountryById().then(res => {
          this.countries = res.data.data.data
        })
      },
      handleEdit(id) {
        this.id = id;
        this.formModalActive = true
        createCountryById().then(res => {
          this.countries = res.data.data.data
        })
      },
      closeModal() {
        this.formModalActive = false;
        this.fetchPartners();
        this.id = null;
      },
      handleDelete(id) {
        if (id) {
          Swal.fire({
            title: this.$t('areYouSure'),
            text: this.$t('wontReturn'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('YesDelete'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              // eslint-disable-next-line no-unused-vars
              deletePartners({
                id
              }).then(res => {
                Swal.fire({
                  icon: 'success',
                  position: 'center',
                  title: this.$t('Deleted'),
                  text: this.$t('dataHasDeleted'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.fetchPartners()
              })
            } else if (result.dismiss === 'cancel') {
              Swal.fire({
                title: this.$t('Cancelled'),
                text: this.$t('yrDataSafe'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      },
    }
  }
</script>

<style scoped>

</style>